import TYPES from '@/types';

// Application
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import {
  BeneficiaryDto,
} from '@/modules/my-investment/allianz/allianz-account/domain/dtos/beneficiary-dto';
import {
  AllianzAccountEntity,
} from '@/modules/my-investment/allianz/allianz-account/domain/entities/allianz-account-entity';
import State from '@/modules/my-investment/allianz/allianz-account/domain/state/state';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class BeneficiariesViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.allianz-dashboard.beneficiaries';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  is_loading = true;

  beneficiaries: Array<BeneficiaryDto> = [];

  allianz_account: AllianzAccountEntity = new State().account;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  loadAllianzAccount = async () => {
    try {
      this.allianz_account = await this.get_allianz_account_query
        .execute({ customer_id: this.customer_id });
      if (this.allianz_account) {
        this.beneficiaries = this.allianz_account.beneficiaries.beneficiaries;
      }
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_account'));
      }
    }
  }

  initialize = async () => {
    await this.loadAllianzAccount();
    this.is_loading = false;
  }
}
