








































































































































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import BeneficiariesViewModel
  from '@/vue-app/view-models/allianz-dashboard/allianz-actions/beneficiaries-view-model';

@Component({
  name: 'BeneficiariesDialog',
})
export default class BeneficiariesDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  beneficiaries_view_model = Vue.observable(new BeneficiariesViewModel());

  async created() {
    await this.beneficiaries_view_model.initialize();
  }
}
